import { Row, Col } from "react-bootstrap";

const Home = () => {

    return (
        <>
            <Row style={{ marginTop: 20 }}>
                <Col>
                </Col>
                <Col xs={8} align="start">
                    <h1>Home</h1><br/>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
            <Col>
                </Col>
                <Col xs={8} align="start">
                    <h5>Interest dates</h5>

                    1. March<br/>
                    1. June<br/>
                    1. September<br/>
                    1. December<br/>

                </Col>
                <Col>
                </Col>
            </Row>
        </>
    );

}


export default Home;