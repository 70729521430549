import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";

const UpperNavbar = () => {
  
    return (
        <>
        <Navbar expand="lg" bg="light" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/">Modena Investments</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav>         
            <Nav.Link><Link to="/">Home</Link></Nav.Link> 
            <Nav.Link><Link to="/investments">List of investments</Link></Nav.Link> 
            <Nav.Link><Link to="/interestcalculation">Interest calculation</Link></Nav.Link> 
            <Nav.Link><Link to="/cashflow">CashFlow</Link></Nav.Link> 
            </Nav>           
            <Nav className='align-items-end'>
            <Nav.Link className='align-items-end' href="/cart"><Badge bg="primary"></Badge></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </>
    );

}

export default UpperNavbar;