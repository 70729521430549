import { Row, Col, Table, Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useState, useRef, useEffect } from "react";
import getAPI from '../api/getInterestCalculationAPI';

const InterestCalculation = ({sessionToken}) => {

    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [interestcalculation, setInterestCalculation] = useState([]);
    const dataLoaded = useRef(false);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const dateFormat = (date) => {
        let dateObject = new Date(date);
        return (dateObject.getDate() + "." + (dateObject.getMonth()+1) + "." + dateObject.getFullYear());
    }

    const handleSelectPreviousMonth = () => {
        const newSelectedMonth = new Date(selectedMonth);
        newSelectedMonth.setMonth(newSelectedMonth.getMonth()-1);
        setSelectedMonth(newSelectedMonth);
    }

    const handleSelectNextMonth = () => {
        const newSelectedMonth = new Date(selectedMonth);
        newSelectedMonth.setMonth(newSelectedMonth.getMonth()+1);
        setSelectedMonth(newSelectedMonth);
    }

   
    useEffect(() => {
        const fetchCalculationList = async ()=> {
            const fetchList = async () => {        
                const config = {
                    headers: {
                      Authorization: 'Bearer ' + sessionToken
                    },
                    params: {
                        month: (selectedMonth.getMonth()+1),
                        year: (selectedMonth.getFullYear())
                    }
                };
                const response = await getAPI.get('', config);
                setInterestCalculation(response.data);
            }
            if (dataLoaded.current === false) {
                fetchList();
            }
            return ()=> { dataLoaded.current = true; }
        }
        fetchCalculationList();
    }, [selectedMonth,sessionToken]); 



    return(
        <>
 <Row style={{ marginTop: 20 }}>
                <Col>
                </Col>
                <Col xs={8} align="start">
                    <h1>Interest Calculation</h1><br/>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
            <Col>
                </Col>
                <Col xs={8} align="end">
                    <h5>
                        <Button variant="success"
                            size="sm"
                            onClick={handleSelectPreviousMonth}>
                            <ChevronLeft />
                        </Button>
                        {monthNames[selectedMonth.getMonth()]} {selectedMonth.getFullYear()}

                        <Button variant="success"
                            size="sm"
                            onClick={handleSelectNextMonth}>
                            <ChevronRight />
                        </Button>
                    </h5>
                </Col>
                <Col>
                </Col>
            </Row>    
            <Row>
                <Col>
                </Col>
                <Col xs={8}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Investor</th>
                                <th>Amount</th>
                                <th>Interest</th>
                                <th>Type</th>
                                <th>Interest Calculation start</th>
                                <th>Interest Calculation end</th>
                                <th>Interest days</th>
                                <th>Interest earned €</th>
                            </tr>
                        </thead>
                        <tbody>
                        {interestcalculation.length < 1 ? "Investeeringud puuduvad :(": 
                         interestcalculation.map((investment) => (
                                        <tr key={investment.id}>
                                            <td>
                                                {investment.id}
                                            </td>
                                            <td>
                                                {investment.name}
                                            </td>
                                            <td>
                                                {investment.amount} €
                                            </td>
                                            <td>
                                                {investment.interest}%
                                            </td>
                                            <td>
                                                {investment.type}
                                            </td>
                                            <td>
                                                {dateFormat(investment.investmentPeriodStart)}
                                            </td>
                                            <td>
                                                {dateFormat(investment.investmentPeriodEnd)}
                                            </td>
                                            <td>
                                                {investment.interestDays}
                                            </td>
                                            <td>
                                                {investment.interestEarned} €
                                            </td>

                                        </tr>
                                    ))
                                    }
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>Total</b></td>
                                        <td><b>{ parseFloat(interestcalculation.reduce((total, currentValue) => total = total + currentValue.interestEarned,0)).toFixed(2)} €
</b></td>
                                    </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                </Col>
            </Row>
                </>
    );
    
    }
    
    
    export default InterestCalculation;