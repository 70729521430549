import { Row, Col, Table } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import getAPI from '../api/getCashFlows';


const CashFlow = ({sessionToken}) => {

    const [cashFlows, setCashFlows] = useState([]);
    const dataLoaded = useRef(false);
    const [selectedDate, ] = useState(new Date());

    useEffect(() => {
        const fetchCashFlowList = async ()=> {
            const fetchList = async () => {        
                const config = {
                    headers: {
                      Authorization: 'Bearer ' + sessionToken
                    },
                    params: {
                        month: (selectedDate.getMonth()+1),
                        year: (selectedDate.getFullYear())
                    }
                };
                const response = await getAPI.get('', config);
                setCashFlows(response.data);
            }
            if (dataLoaded.current === false) {
                fetchList();
            }
            return ()=> { dataLoaded.current = true; }
        }
        fetchCashFlowList();
    }, [sessionToken,selectedDate]); 


    return(
        <>
<Row style={{ marginTop: 20 }}>
                <Col>
                </Col>
                <Col xs={8} align="start">
                    <h1>Cash-flows</h1><br/>
                </Col>
                <Col>
                </Col>
            </Row>            
            <Row>
                <Col>
                </Col>
                <Col xs={8}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>Interest payment</th>
                                <th>Principal payment</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {cashFlows.length < 1 ? "No outgoing cash-flows :)": 
                         cashFlows.map((cashflow) => (
                                        <tr key={cashflow.id}>
                                            <td>
                                                {cashflow.year}
                                            </td>
                                            <td>
                                                {cashflow.month}
                                            </td>
                                            <td>
                                                {parseFloat(cashflow.interestPayment).toFixed(2)} €
                                            </td>
                                            <td>
                                                {parseFloat(cashflow.principalPayment).toFixed(2)} €
                                            </td>
                                            <td>
                                            {parseFloat(cashflow.interestPayment*1 + cashflow.principalPayment*1).toFixed(2)} €
                                            </td>
                                        </tr>
                                    ))
                                    }
                        </tbody>
                    </Table>
                </Col>
                <Col>
                </Col>
            </Row>        </>
    );


}

export default CashFlow;